import React from 'react'
import ResumeCard from './ResumeCard'
import { motion } from "framer-motion"

const Education = () => {
  return (
    <motion.div initial={{opacity:0}}  animate={{ opacity: 1, transition: { duration: 0.5 } }} className="w-full flex gap-20 ">

      <div className="w-1/2">
      <div className="py-12 font-titleFont ">
        <p className="text-sm text-designColor tracking-[4px]">About</p>
        <h2 className="text-4xl font-bold">Academic Qualifications</h2>
      </div>
      <div className="mt-14 w-full h-[1000px] border-l-[6px] border-black flex flex-col gap-10 border-opacity-30">
          <ResumeCard
            title="AISSE-CBSE(10th Board Exam)"
            subTitle="Jawahar Navodaya Vidyalaya, TVM KL (2013-2018)"
            result="88%"
            des="The training provided by universities in order to prepare people to work in various sectors of the economy or areas of culture."
          />
          <ResumeCard
            title="AISSCE-CBSE(12th Board Exam)"
            subTitle="Jawahar Navodaya Vidyalaya, TVM KL (2018-2020)"
            result="90%"
            des="Higher education is tertiary education leading to award of an academic degree. Higher education, also called post-secondary education."
          />
          <ResumeCard
            title="B.Tech CSE (Spcl AI and Ml)"
            subTitle="CT University, LDH PB (2020-2024)"
            result="8.5/10"
            des="Secondary education or post-primary education covers two phases on the International Standard Classification of Education scale."
          />
        </div>
      </div>

      <div className="w-1/2">
      <div className="py-12 font-titleFont ">
        <p className="text-sm text-designColor tracking-[4px]">About</p>
        <h2 className="text-4xl font-bold">Previous Institutions</h2>
      </div>
      <div className="mt-14 w-full h-[1000px] border-l-[6px] border-black flex flex-col gap-10 border-opacity-30">
      <ResumeCard
            title="Jawahar Navodaya Vidyalaya"
            subTitle="Chettachal, P.O Nedumangad, Thiruvananthapuram, Kerala"
            result="CBSE-HRDO"
            des="Jawahar Navodaya Vidyalayas are fully residential, co-educational schools affiliated to CBSE, New Delhi having classes up to 12 standard. Navodaya Vidyalaya Samiti, an autonomous Organization under the ministry of HRD, Government of IndiaThe training provided by universities in order to prepare people to work in various sectors of the economy or areas of culture."
          />
          <ResumeCard
            title="Jawahar Navodaya Vidyalaya"
            subTitle="Chettachal, P.O Nedumangad, Thiruvananthapuram, Kerala"
            result="CBSE-HRDO"
            des="Jawahar Navodaya Vidyalayas are fully residential, co-educational schools affiliated to CBSE, New Delhi having classes up to 12 standard. Navodaya Vidyalaya Samiti, an autonomous Organization under the ministry of HRD, Government of IndiaHigher education is tertiary education leading to award of an academic degree. Higher education, also called post-secondary education."
          />
          <ResumeCard
            title="CT University"
            subTitle="Ferozepur Road, Sidhwan Khurd, Ludhiana, Punjab"
            result="AICTE-UGC"
            des="CTU is multi-disciplinary, student-centric, industry driven and research-focused university offering a full range of academic programs at the diploma, undergraduate, post graduate and doctoral levels. The CT University campus is a digitally transformed university campus meant to facility New Education Policy. Other main features include a 24*7 robotics and automation lab, BOSCH lab, the Research  & Innovation for Excellence working lab"
          />
        </div>
      </div>
      </motion.div>
  )
}

export default Education