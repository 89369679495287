import React from "react";
import Title from "../layout/Title";
import Aboutme from "./Aboutme";
import Certificates from "./Certificates";
import Education from "./Education";
import Skills from "./Skills";
import { useState } from "react";

function Resume() {
  const [aboutmeData, setAboutmeData] = useState(true);
  const [educationData, setEducationData] = useState(false);
  const [skillData, setSkillData] = useState(false);
  const [certificatesData, setCertificatesData] = useState(false);
  return (
    <section id="resume" className="w-full py-10 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <Title
          title="A Fresher ready to start his adventure"
          desc="My Resume"
        />
      </div>
      <div className="w-full flex gap-20">
        <ul className="w-full grid grid-cols-4">
          <li
            onClick={() =>
              setAboutmeData(true) &
              setEducationData(false) &
              setSkillData(false) &
              setCertificatesData(false)
            }
            className={`${
              aboutmeData
                ? "border-designColor rounded-lg"
                : "border-transparent"
            } resumeLi`}
          >
            About Me
          </li>
          <li
            onClick={() =>
              setAboutmeData(false) &
              setEducationData(true) &
              setSkillData(false) &
              setCertificatesData(false)
            }
            className={`${
              educationData
                ? "border-designColor rounded-lg"
                : "border-transparent"
            } resumeLi`}
          >
            Education
          </li>
          <li
            onClick={() =>
              setAboutmeData(false) &
              setEducationData(false) &
              setSkillData(true) &
              setCertificatesData(false)
            }
            className={`${
              skillData
                ? "border-designColor rounded-lg"
                : "border-transparent"
            } resumeLi`}
          >
            Professional Skills
          </li>
          <li
            onClick={() =>
              setAboutmeData(false) &
              setEducationData(false) &
              setSkillData(false) &
              setCertificatesData(true)
            }
            className={`${
              certificatesData
                ? "border-designColor rounded-lg"
                : "border-transparent"
            } resumeLi`}
          >
            Certificates
          </li>
        </ul>
      </div>

      {aboutmeData && <Aboutme />}
      {educationData && <Education />}
      {skillData && <Skills />}
      {certificatesData && <Certificates />}
    </section>
  );
}

export default Resume;
