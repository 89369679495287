import React from "react";
import { motion } from "framer-motion";
import {
  c18, c19, c20, c21, c22, c23, c24,c8,c10, c25, c26, c27, c28, c29,c31, c32,c33,
  c1, c2, c3, c4, c5, c6, c7,c9,c11, c12, c13, c14, c15, c16, c17,pdfFile
} from "../../assets";
import Carousel from "./Carousel";

const Certificates = () => {
  const slides = [
    c18, c19, c20, c21, c22, c23, c24,c8,c10, c25, c26, c27, c28, c29,c31, c32,c33,
  c1, c2, c3, c4, c5, c6, c7,c9,c11, c12, c13, c14, c15, c16, c17,
  ];  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex gap-20"
    >
      <div className="w-1/2">
        <div className="w-full flex flex-col items-center">
          <div className="py-12 font-titleFont flex flex-col items-center">
            <p className="text-sm text-designColor tracking-[4px]">My</p>
            <h2 className="text-4xl font-bold">Certificates</h2>
          </div>
          <div className="w-[600px] h-[500px]">
            <Carousel autoSlide={true}>
              {slides.map((s) => (
                <img src={s} alt="certificates" />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="w-1/2">
        <div className="w-full flex flex-col items-center">
          <div className="pt-40">
            <p className="text-justify">
              Completing a multitude of courses and projects takes a significant
              amount of hard work and dedication. It involves setting goals,
              staying organized, and consistently putting in the time and effort
              required to achieve those goals. One must be willing to invest
              their energy and focus into the learning process, continuously
              expanding their knowledge and skills. Along the way, there may be
              obstacles and challenges that must be overcome, but perseverance
              and determination are key to success. Whether it is through
              seeking help from others, conducting extensive research, or simply
              refusing to give up, pushing through these difficulties is what
              sets apart those who have truly dedicated themselves to their
              education. The end result of all this hard work is a deeper
              understanding of the subject matter and a sense of accomplishment
              that can only come from the satisfaction of having achieved one's
              goals through their own efforts.
            </p>
          </div>
          <div className="pt-20">
            <h1 className="text-gray-300 text-center">
              For Downloading My resume
            </h1>
            <div class="py-3 pl-5">
              <button
                type="button"
                class="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
              ><a href = {pdfFile} download = "AbinBinu-Resume" > 
                  Download Resume
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Certificates;
