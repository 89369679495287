import React from "react";
import { Project1, Project2, Project3, Project4, Project5, Project6 } from "../../assets";
import Title from "../layout/Title";
import ProjectCard from "./ProjectCard";

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full pb-20 border-b-[1px] border-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title title="Visit My Projects And Give Your Feedbacks" desc="My Projects"/>
      </div>
      <div className="grid grid-cols-3 gap-14 gap-y-20">
      <ProjectCard
          title="ASL - RealTime Gesture Recognition"
          des="A project that helps people who have earing and talking impairment,using Ai and ML.
          The ML model recognizes 26 Alphabets of ASL-American Sign,show the camera in your device the gesture and it would 
          give its english Alphabet in real time  "
          src={Project1}
          lin="https://github.com/AZURIIEAL/ASL_Gesture_Recognition"
        />
              <ProjectCard
          title="D-KSRTC - Bus Booking Website"
          des="Engineered a dynamic bus booking system with Angular, HTML,
           SASS, TS, JS for frontend, and ASP.NET Core 7, C# for backend. Implemented Mediatr,
           Repository patterns, T-SQL, Entity Framework, and RESTful APIs for efficient communication."
          src={Project2}
          lin="https://github.com/AZURIIEAL/D-KSRTC"
        />
              <ProjectCard
          title="Neural Networks From Scratch"
          des="Implemented Neural Networks from scratch, solely using core concepts without mainstream
           libraries like scikit-learn or TensorFlow. The project relied exclusively on numpy for operations,
           showcasing a deep understanding of neural network fundamentals and coding proficiency."
          src={Project3}
          lin="https://github.com/AZURIIEAL/Neural_Networks_From_Scratch-No_Libraries-"
        />
              <ProjectCard
          title="Face Proximity Detection From Camera"
          des="Developed a Face Proximity Analyzer using Python, OpenCV, and physics. Excels in real-time face detection and distance measurement.
           Applications include human-computer interaction, security enhancement, and robotics."
          src={Project4}
          lin="https://github.com/AZURIIEAL/Face_depth_from_Camera"
        />
              <ProjectCard
          title="HarmoniX - Music Player Android App"
          des="Developed an Android music player app using Ionic, built on AngularJS and Apache Cordova. Leveraged the power
           of Ionic for a seamless cross-platform experience,
           integrating AngularJS for robust frontend functionality and Apache Cordova for native device access."
          src={Project5}
          lin="https://www.google.com"
        />
              <ProjectCard
          title="ASPDotNet Core API- CRUD"
          des="Developed a basic ASP.NET Core website illustrating CRUD operations with ASP.NET Core API. 
          Demonstrates fundamental CRUD functionalities, showcasing ASP.NET Core's capabilities in web development.
           This website is a practical example of implementing Create, Read, Update, and Delete operations."
          src={Project6}
          lin="https://github.com/AZURIIEAL/CRUD-API-_ASP.Net-6.5"
        />
        
      </div>
      <div className="w-full px-48 flex justify-center">
      <div className="pt-20">
          <h1 className="text-gray-300 ">For More Visit</h1>
          <div class="py-3">
              <button
                type="button"
                class="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
              >
                <a href="https://github.com/AZURIIEAL" target="_blank" rel="noreferrer">
                  Github
                </a>
              </button>
            </div>
      </div>
      </div>
    </section>
  );
};

export default Projects;
