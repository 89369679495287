import React from "react";

export const Card = ({ title, des, icon }) => {
  return (
    <div
      className="w-full px-12 h-80 py-10 rounded-lg shadow-shadowOne flex items-center
     bg-gradient-to-r from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-black hover:to-[#1e2024]
      transition-colors duration-100"
    >
      <div className="flex flex-col gap-8 overflow-y-hidden">
        <div>
          <span className="text-5xl text-designColor">{icon}</span>
        </div>
        <div className="flex flex-col gap-5">
          <h2 className="text-2xl font-bold font-titleFont text-gray-300 translate-y-10 group-hover:translate-y-0 transition-transform duration-500">
            {title}
          </h2>
          <p className="pt-6 text-justify translate-y-40 group-hover:translate-y-0 transition-transform duration-500">{des}</p>
        </div>
      </div>
    </div>
  );
};
