import React from "react";
import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaGlobe,FaBars } from "react-icons/fa";
import { SiProgress, SiAntdesign } from "react-icons/si";
import { Card } from "../layout/Card";
import Title from "../layout/Title";

function Features() {
  return (
    <section id="features" className="w-full pb-20 border-b-[1px] border-black">
      <Title title="Features" desc="What I Do" />
      <div className="grid grid-cols-3 gap-20">
        <Card
          title="AI/ML Models"
          des="I have a good understanding of the basics of artificial intelligence and machine learning.
          I also have experience working with popular AI frameworks such as TensorFlow and Keras."
          icon={<AiFillAppstore />}
        />
        <Card
          title="App Development"
          des="I have an understanding of working with Mobile apps and their working.
          I also have experience working with Dart,Java, Flutter,React Native and Android Studio."
          icon={<FaMobile />}
        />
         <Card
          title="Web Development"
          des="Web-development has always spiked my interests,I have worked with MERN Stack,I also posses knowledge of networking and its essentials."
          icon={<FaGlobe />}
        />
        <Card
          title="Game Development"
          des="Math,physics and Computer Science are my fav subjects and in Game dev all of them comes together.
          I can work with Unity Engine and have experiance with Indie game development."
          icon={<SiProgress />}
        />
        <Card
          title="Minimal Graphics"
          des="For my projects I started doing Photoshop and Illustrator,
          by which now I can make minimal designs,
          edit Images according to my needs or for my websites/Projects."
          icon={<FaBars/>}
          />
        <Card
          title="Quality over Quantity"
          des="I manily focus on the quality of the efforts taken and output given by me instead of how much/how many I did.
          Hence I can create products according to the needs of my clients."
          icon={<SiAntdesign />}
        /> 
       
      </div>
    </section>
  );
}

export default Features;
