import React from "react";

const ProjectCard = ({ title, src, des, lin }) => {
  return (
    <div className="w-80 h-80 rounded-lg shadow-shadowOne justify-center items-center text-center">
      <div className="card w-80 h-80">
        <div className="content">
          <div className="front relative">
            <img
              className="w-full max-w-lg h-80 rounded-lg overflow-hidden relative"
              src={src}
              alt="aiproject"
            ></img>
            <div className="absolute inset-0 bg-gray-900 bg-opacity-50" ></div>
            <h1 className="centeredaa text-[18px] font-bold  font-titleFont text-white absolute">
                {title}
            </h1>
          </div>
          <div
            className="back h-80 pt-10 text-15px text-justify px-5 group hover:bg-gradient-to-b hover:from-black hover:to-[#1e2024]
      transition-colors duration-100"
          >
            {des}
            <div class="flex space-x-2 justify-center py-5">
              <button
                type="button"
                class="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
              >
                <a href={lin} target="_blank" rel="noreferrer">
                  Goto Project
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
