import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import {
  FaInstagram,
  FaFacebook,
  FaLinkedinIn,
  FaGithub,
  FaWhatsapp
} from "react-icons/fa";
import { abin1 } from "../../assets";

function Home() {
  const [text_for_typewriter] = useTypewriter({
    words: [
      "Philosopher of Code.",
      "Web Socratic.",
      "AI Ponderer.",
      "XR Enthusiast.",
      "Game Existentialist.",
      "Script Zen Master.",
      "Tech Contemplator.",
    ],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 3000,
  });
  return (
    <section
      id="home"
      className="w-full py-20 flex flex-col gap-10 xl:gap-0 lgl:flex-row items-center border-b-[1px] font-titleFont border-b-black"
    >
      <div className="w-1/2 flex flex-col gap-20">
        <div className="flex flex-col gap-5">
          <h4 className="text-lg font-normal ">Welcome to my Portfolio</h4>
          <h1 className="text-6xl font-bold text-white">
            Hi, I'm{" "}
            <span className="text-designColor capitalize">Abin Binu</span>
          </h1>
          <h2 className="text-4xl font-bold text-white ">
            <span>{text_for_typewriter}</span>
            <Cursor
              cursorBlinking="false"
              cursorStyle="«"
              cursorColor="#ff014f"
            />
          </h2>
          <p>
            My Philosophy is that, each person is unique and their thoughts,
            experiences, and beliefs shape their individuality. The pursuit of
            self-discovery is a never-ending journey, constantly unfolding with
            new experiences. One is continually growing and evolving as they
            navigate existence.
          </p>
          <p className="font-style: italic pt-4">
            "Technology is the key to unlocking the infinite possibilities of
            tomorrow"
          </p>
        </div>
        <div>
          <h2 className="text-base mb-4 font-titleFont uppercase">
            Find me at
          </h2>
          <div className="flex gap-4">
            <button className="contacticon">
              <a
                href="https://www.instagram.com/abin.xo/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
            </button>
            <button className="contacticon">
              <a
                href="https://www.facebook.com/Abin.Binu.678/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook />
              </a>
              </button>
              <button className="contacticon">
                <a href="https://wa.me/+918606889957"
                target="_blank"
                rel="noreferrer">
                  <FaWhatsapp/>
                  </a>
              </button>
            <button className="contacticon">
              <a
                href="https://www.linkedin.com/in/abin-b-4768ba20b/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </button>
            <button className="contacticon">
              <a
                href="https://github.com/AZURIIEAL"
                target="_blank"
                rel="noreferrer"
              >
                <FaGithub />
              </a>
            </button>
          </div>
        </div>
      </div>
      <div className="w-full lgl:w-1/2 flex justify-center items-center relative">
        <img className="z-10 shadow-shadowOne" src={abin1} alt="Abin" />
        
      </div>
    </section>
  );
}

export default Home;
