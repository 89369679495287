import React from "react";
import {pdfFile} from "../../assets";
import { motion } from "framer-motion";
import {
  FaInstagram,
  FaLinkedinIn,
  FaGithub,
  FaFacebook,
} from "react-icons/fa";
import { abin2 } from "../../assets";

const Aboutme = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex gap-20"
    >
      <div className="w-full flex flex-col gap-10 xl:gap-0 lgl:flex-row  font-titleFont">
        <div className="w-1/2">
          <div className="font-titleFont pt-10 pb-5">
            <p className="text-sm text-designColor tracking-[4px]">About</p>
            <h2 className="text-4xl font-bold">ME</h2>
          </div>
          <p className="text-justify">
            An independent and self-motivated programmer with proven coding,
            communication and working skills. I'm a Hardworking,studious
            person.I really like programming and I want to pursue my future in
            that area.I really like traveling and meeting new people.This helps
            me to get really good experiences that help me to make good
            decisions for my future.I have studied in differnt parts of my
            country(India),which made me adapt to differnt cultures and
            places,so I can adapt to whatever work environment and give my best
            performance.I am a person who likes history,art and philosophy.My
            hobbies are basically reading research papers and books ,watching
            Anime,Strategic Online games and learning new skills.
          </p>
          <div>
            <div className="font-titleFont pt-10 pb-5">
              <p className="text-sm text-designColor tracking-[4px]">
                Qualities
              </p>
              <h2 className="text-4xl font-bold">I have</h2>
            </div>
            <p className="text-justify">
              As a good human being ,I have developed many skills and personal
              qualities for improving my perfomace socially and ethically,some
              of them are given below,
            </p>

            <ul className="list-disc text-justify px-10 pt-5">
              <li>Always pick the right tools for my projects.</li>
              <li>Keep my code clean and readable.</li>
              <li>A Team player with a can-do attitude.</li>
              <li>Willing to adapt to new technologies and places.</li>
              <li>Efficient through optimization</li>
              <li>A Problem-solver with good communication skills.</li>
              <li>Aim for success at the start</li>
              <li>Self-evaluate along the way</li>
              <li>Research, planning and organizing</li>
              <br></br>
              <div class="py-3 pl-5">
              <button
                type="button"
                class="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
              ><a href = {pdfFile} download = "AbinBinu-Resume" > 
                  Download Resume
                </a>
              </button>
            </div>
            </ul>
          </div>
        </div>
        <div className="w-1/2 px-20">
          <div className="font-titleFont pt-10 pb-5">
            <p className="text-sm text-designColor tracking-[4px]">Languages</p>
            <h2 className="text-4xl font-bold">I Know</h2>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">English</p>
            <span className="w-3/4 h-2 bg-black bg-opacity-40 rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-[100%] h-full bg-gradient-to-r  from-blue-600 via-pink-500 to-red-500  rounded-md relative"
              >
                <span className="absolute -top-7 right-0">5/5</span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Malayalam</p>
            <span className="w-3/4 h-2 bg-black bg-opacity-40 rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-[100%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 rounded-md relative"
              >
                <span className="absolute -top-7 right-0">5/5</span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Hindi</p>
            <span className="w-3/4 h-2 bg-black bg-opacity-40 rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-[80%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500  rounded-md relative"
              >
                <span className="absolute -top-7 right-0">4/5</span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Tamil</p>
            <span className="w-3/4 h-2 bg-black bg-opacity-40 rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-[80%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500  rounded-md relative"
              >
                <span className="absolute -top-7 right-0">4/5</span>
              </motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm uppercase font-medium">Punjabi</p>
            <span className="w-3/4 h-2 bg-black bg-opacity-40 rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-[60%] h-full bg-gradient-to-r  from-blue-600 via-pink-500 to-red-500  rounded-md relative"
              >
                <span className="absolute -top-7 right-0">3/5</span>
              </motion.span>
            </span>
          </div>
          <div className="py-20">
          <div className="w-[300px] h-[440px] rounded-lg shadow-shadowOne text-center flex flex-col items-center">
          <div className="pt-5">
          <img className="w-[260px] h-[260px] shadow-shadowOne" src={abin2} alt="Abin" />
          </div>
          <div className="font-titleFont py-3">
            <h2 className="text-xl text-designColor">Contacts</h2>
            <p className="text-sm">abinbinu678@gmail.com</p>
            <p className="text-sm">+91 8606889957</p>
          </div>
          <div className="flex gap-4">
            <button className="contacticon">
              <a
                href="https://www.instagram.com/abin.xo/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
            </button>
            <button className="contacticon">
              <a
                href="https://www.facebook.com/Abin.Binu.678/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook />
              </a>
              </button>
            <button className="contacticon">
              <a
                href="https://www.linkedin.com/in/abin-b-4768ba20b/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </button>
            <button className="contacticon">
              <a
                href="https://github.com/AZURIIEAL"
                target="_blank"
                rel="noreferrer"
              >
                <FaGithub />
              </a>
            </button>
          </div>    
          </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Aboutme;
