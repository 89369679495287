import React from "react";
import Home from "./components/home/Home";
import Features from "./components/features/Features";
import Navbar from "./components/navbar/Navbar";
import Projects from "./components/project/Projects";
import Resume from "./components/resume/Resume";
import FooterBottom from "./components/Footer/FooterBottom";



function App() {
  return (
    <div className="w-full h-auto bg-bodyColor text-lightText">
      <Navbar/>
      <div className="max-w-screen-xl mx-auto">
      <Home/>
      <Features/>
      <Projects/>
      <Resume/>
      <FooterBottom/>
      </div>
    </div>
  );
}

export default App;
